<template>
    <div class="select-input">
        <span class="input-icon">
            <img
                :src="inputIcon"
                alt="icon"
            />
        </span>
        <input
            class="input"
            type="text"
            placeholder="搜索"
            v-model="selectVal"
            @input.stop="selectInput($event)"
            v-on:click.stop="selectFocus($event)"
        />
        <slot v-if="dropDownSearchType"></slot>
    </div>
</template>
<script>
import {debounce} from "../../../hook.js";
export default {
    name: "SelectInput",
    props: {
        dropDownSearchType: Boolean,
    },
    data() {
        return {
            selectVal: "",
        };
    },
    computed: {
        inputIcon() {
            return require("../../../img/select.png");
        },
    },
    methods: {
        selectInput: debounce(function (ev) {
            const e = ev || window.e,
                tar = e.target || e.srcElement;
            e.preventDefault();
            e.stopPropagation()

            this.$emit("selectInput", tar.value);
            return false
        }, 500),
        selectFocus(ev) {
            const e = ev || window.e;
            e.preventDefault();
            e.stopPropagation();
            console.log(e.stopPropagation,'e.stopPropagation')
            setTimeout(() => {
                console.log('1')
                this.$emit("selectFocus");
            })
            return false
        },
        handlerTreeNodeClick (data) {
            console.log(data,'data')
        },
    },
    watch: {
        dropDownSearchType: {
            handler(newVal) {
                if (!newVal) {
                    this.selectVal = '';
                }
            }
        },
        deep: true
    }
};
</script>
<style lang="scss" scoped>
.select-input {
    position: relative;
    width: 300px;
    height: 36px;
    border: 1px solid #edf0f2;
    border-radius: 4px;
    .input-icon {
        width: 13px;
        height: 13px;
        display: inline-block;
        position: absolute;
        left: 11px;
        top: 7px;
        z-index: 1;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .input {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        color: #2b2f33;
        border: none;
        outline: none;
        box-sizing: border-box;
        padding: 0 34px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
    }
    input::-webkit-input-placeholder {
        color: #bbbdbf;
    }
    input:-moz-placeholder {
        color: #bbbdbf;
    }
    input::-moz-placeholder {
        color: #bbbdbf;
    }
    input:-ms-input-placeholder {
        color: #bbbdbf;
    }
}
</style>
