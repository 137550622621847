<template>
    <div class="submit-wrapper">
        <button class="cancel" @click.stop="cancel">取消</button>
        <button class="determine" @click.stop="determine">确定</button>
    </div>
</template>
<script>
export default {
    name: "Submit",
    methods: {
        determine () {
            this.$emit('determine')
        },
        cancel () {
            this.$emit('cancel')
        }
    }

}
</script>
<style lang="scss" scoped>
    .submit-wrapper {
        width: 100%;
        height: 60px;
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        padding: 0 36px;
        .determine {
            background-color: #3C7FFF;
            border: 1px solid #3C7FFF;
            color: #ffffff;
        }
        .cancel {
            background-color: #ffffff;
            border: 1px solid #E1E2E7;
            color: #333333;
        }
        button {
            background: #ffffff;
            border-color: #ffffff;
            color: #333333;
            box-sizing: border-box;
            // padding: 6px 20px;
            width: 64px;
            height: 32px;
            margin: 0 5px;
            border-radius: 4px;
            -webkit-box-shadow: none;
            box-shadow: none;
            font-size: 14px;
            cursor: pointer;
        }
        button:focus{outline:none;}
    }
</style>
