<template>
    <!-- 已选 -->
    <div class="select box">
        <select-title title="已选择"></select-title>
        <!-- 是否设置为当前负责人 -->
        <el-checkbox style="margin-bottom: 20px;" @change="changeStatus" true-label="1" false-label="0" v-model="checked">设置为当前负责人</el-checkbox>
        <!-- 已经选择过的：无法修改此用户 -->
        <div class="select-wrapper">
            <div
                v-for="(item, index) of alreadySelectNodeData"
                :key="index"
                class="select-item"
                @click="handlerDelTreeNode($event, item)"
            >
                <div class="select-item-content">
                    <img :src="personnelImg" alt="personnel">
                    <span class="title">{{ item.name }}</span>
                </div>
            </div>
            <!-- 已选择人员 -->
            <div
                v-for="(item, index) of selectTreeNodeData"
                :key="index"
                class="select-item"
                @click="handlerDelTreeNode($event, item)"
            >
                <div class="select-item-content">
                    <img v-if="['student', 'teacher'].includes(item.type)" :src="personnelImg" alt="personnel">
                    <img v-else :src="departmentImg" alt="department">
                    <span class="title">{{ item.name }}</span>
                </div>
                <span class="del-wrapper">
                    <img :src="delImg" alt="del">
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import SelectTitle from "../../components/Title/SelectTitle.vue";
export default {
    name: "TreeShapeSelected",
    components: {
        SelectTitle
    },
    data(){
        return {
            checked:"1"
        }
    },
    props: {
        selectTreeNodeData: {
            type: Array,
            default: () => {
                return []
            }
        },
        alreadySelectNodeData :{
            type: Array,
            default: () => {
                return []
            }
        }
    },
    computed: {
        departmentImg () {
            return require('../../../img/department.png')
        },
        personnelImg () {
            return require('../../../img/personnel.png')
        },
        delImg () {
            return require('../../../img/del.png')
        }
    },
    methods: {
        handlerDelTreeNode(e, item) {
            this.$emit('handlerDelTreeNode', {e: e, item: item})
        },
        changeStatus(){
            this.$emit('handlerSetUser', this.checked)
        },

    },
    mounted(){
        this.changeStatus();
    }
};
</script>
<style lang="scss" scoped>
.box {
    width: 350px;
    box-sizing: border-box;
    padding: 0 0 0 24px;
}
.select {
    border-left: 1px solid #ddd;
    .select-wrapper {
        width: 100%;
        height: 380px;
        overflow: auto;
        .select-item {
            width: 140px;
            height: 32px;
            margin: 0px 8px 8px 0px;
            box-sizing: border-box;
            padding: 4px 8px 4px 4px;
            font-size: 14px;
            background-color: #F5F5F5;
            border-radius: 3px;
            color: #333;
            display: flex;
            justify-content: space-between;
            float: left;
            .select-item-content {
                display: flex;
                align-items: center;
                .title {
                    max-width: 80px;
                    margin-left: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .del-wrapper {
                width: 8px;
                height: 8px;
                cursor: pointer;
            }
        }
    }
}
</style>
