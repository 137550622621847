<template>
    <!-- 可选 -->
    <div class="tree-wrapper box">
        <!-- title -->
        <select-title title="选择"></select-title>
        <!-- 搜索 -->
        <select-input
            :dropDownSearchType="dropDownSearchType"
            @selectFocus="selectFocus"
            @selectInput="selectInput"
        >
            <select-option
                :searchDropdownData="searchDropdownData"
                :searchOptionalLoadTitle="searchOptionalLoadTitle"
                @handlerSelectTreeNodeClick="handlerSelectTreeNodeClick"
            ></select-option>
        </select-input>
        <!-- 面包屑 -->
        <Breadcrumbs
            :breadcrumbsList="breadcrumbsList"
            @changeTreeListLevel="changeTreeListLevel"
        />
        <!-- 选择数据 -->
        <div
            class="tree-option"
            v-if="treeShowList && treeShowList.length > 0"
        >
            <div v-if="checkUserOnly">
                <div class="all-check" v-if="treeShowList[0].type !== 'organ'">
                    <input
                        class="input"
                        type="checkbox"
                        id="all"
                        :checked="allCheck"
                        @click="handlerAllTreeNodeClick($event)"
                    />
                    <label
                        for="all"
                        class="all-check-label"
                    >全选</label
                    >
                </div>
            </div>
            <div v-else>
                <div class="all-check" v-if="multiple">
                    <input
                        class="input"
                        type="checkbox"
                        id="all"
                        :checked="allCheck"
                        @click="handlerAllTreeNodeClick($event)"
                    />
                    <label
                        for="all"
                        class="all-check-label"
                    >全选</label
                    >
                </div>
            </div>
            <!-- 列表 -->
            <div class="tree-list">
                <div
                    v-for="(item, index) of treeShowList"
                    :key="index"
                >
                    <div class="tree-item">
                        <div class="input-item">
                            <div v-if="checkUserOnly">
                                <input
                                    v-if="item.type !== 'organ' && !item.disabled"
                                    class="input"
                                    type="checkbox"
                                    :id="item.id"
                                    :checked="item.checked"
                                    :disabled="item.disabled"
                                    @click="handlerTreeNodeClick($event, item)"
                                />
                                <input
                                    v-if="item.type !== 'organ' && item.disabled"
                                    class="input disabled-input"
                                    type="checkbox"
                                    :id="item.id"
                                    checked="true"
                                    disabled="true"
                                    @click="handlerTreeNodeClick($event, item)"
                                />
                            </div>
                            <div v-else-if="checkAll">
                                <input
                                    class="input"
                                    type="checkbox"
                                    :id="item.id"
                                    :checked="item.checked"
                                    :disabled="item.disabled"
                                    @click="handlerTreeNodeClick($event, item)"
                                />
                            </div>
                            <div v-else>
                                <input
                                    v-if="multiple"
                                    class="input"
                                    type="checkbox"
                                    :id="item.id"
                                    :checked="item.checked"
                                    :disabled="item.disabled"
                                    @click="handlerTreeNodeClick($event, item)"
                                />
                                <input
                                    v-else-if="!multiple && item.type !== 'organ' && item.disabled"
                                    class="input disabled-input"
                                    type="checkbox"
                                    :id="item.id"
                                    :checked="true"
                                    disabled="true"
                                />
                                <input
                                    v-else-if="!multiple && item.type !== 'organ'&& !item.disabled"
                                    class="input"
                                    type="checkbox"
                                    :id="item.id"
                                    :checked="item.checked"
                                    @click="handlerTreeNodeClick($event, item)"
                                />
                            </div>
                            <label
                                class="label"
                                :for="item.id"
                                >{{ item.name }}</label
                            >
                        </div>
                        <div
                            v-if="
                                !item.checked &&
                                !['student', 'teacher'].includes(item.type)
                            "
                            class="tree-next"
                            @click="treeNodeNextLevelClick(item)"
                        >
                            <img
                                class="tree-next-img"
                                :src="treeNextImg"
                                alt="next"
                            />
                            <span class="tree-next-levels">下级</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="optional-load-title">
            {{ optionalLoadTitle }}
        </div>
    </div>
</template>

<script>
import SelectTitle from "../../components/Title/SelectTitle.vue";
import SelectInput from "../../components/SelectInput/index.vue";
import SelectOption from "../../components/SelectOption/index.vue";
import Breadcrumbs from "../../components/Breadcrumbs/index.vue";

export default {
    name: "Optional",
    components: {
        SelectTitle,
        SelectInput,
        SelectOption,
        Breadcrumbs,
    },
    props: {
        allCheck: Boolean,
        multiple: Boolean,
        dropDownSearchType: Boolean,
        treeShowList: Array,
        breadcrumbsList: Array,
        optionalLoadTitle: String,
        searchDropdownData: Array,
        searchOptionalLoadTitle: String,
        checkUserOnly: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        checkAll: {
            type: Boolean,
            default: () => {
                return false;
            },
        }
    },
    computed: {
        treeNextImg() {
            return require("../../../img/next-levels.png");
        },
    },
    methods: {
        selectFocus() {
            this.$emit("selectFocus");
        },
        selectInput(inputVal) {
            this.$emit("selectInput", inputVal);
        },
        handlerAllTreeNodeClick(ev) {
            this.$emit("handlerAllTreeNodeClick", {ev: ev});
        },
        handlerTreeNodeClick(e, item) {
            console.log(item,'选择的item')
            if (!this.multiple && item.type == 'origin') {
                return;
            }
            this.$emit("handlerTreeNodeClick", {e: e, item: item});
        },
        treeNodeNextLevelClick(item) {
            this.$emit("treeNodeNextLevelClick", {treeNode: item});
        },
        changeTreeListLevel(item) {
            this.$emit("changeTreeListLevel", {item: item});
        },
        handlerSelectTreeNodeClick (data) {
            const { e, item } = data;
            this.$emit("handlerTreeNodeClick", {e: e, item: item});
        }
    },
};
</script>
<style lang="scss" scoped>
.box {
    width: 350px;
    box-sizing: border-box;
    padding: 0 0 0 24px;
}
.tree-wrapper {
    .tree-option {
        width: 100%;
        .all-check {
            display: flex;
            align-items: center;
            .all-check-label {
                line-height: 20px;
                display: inline-block;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #2b2f33;
                margin-left: 8px;
            }
        }
        .tree-list {
            width: 100%;
            height: 260px;
            overflow: auto;
            .tree-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;
                padding: 8px 16px 8px 0px;
                .input-item {
                    display: flex;
                    align-items: center;
                    width: 220px;
                    .label {
                        margin-left: 8px;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #2b2f33;
                        cursor: pointer;
                    }
                }
                .tree-next {
                    width: 48px;
                    cursor: pointer;
                    .tree-next-img {
                        width: 10px;
                        height: 13px;
                    }
                    .tree-next-levels {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #3C7FFF;
                        margin-left: 6px;
                    }
                }
            }
        }
    }
    .optional-load-title {
        font-size: 14px;
        color: #2b2f33;
    }
}
.input {
    width: 18px;
    height: 18px;
}
input[type="checkbox"] {
    visibility: hidden;
    position: relative;
}
//针对火狐浏览器的样式
@media screen and (min--moz-device-pixel-ratio:0) {
	input[type="checkbox"] {
        visibility: visible;
        position: relative;
    }
}
input[type="checkbox"]:after {
    content: "";
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    visibility: visible;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #e1e3e6;
}
input[type="checkbox"]:checked::after {
    background: #3C7FFF;
}
input[type="checkbox"]:checked::before {
    content: "";
    display: block;
    position: absolute;
    left: 4px;
    top: 4px;
    width: 10px;
    height: 6px;
    border: 2px solid #fff;
    border-top-color: transparent;
    border-right-color: transparent;
    -ms-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -webkit-transform: rotate(-60deg);
    transform: rotate(-45deg);
    visibility: visible;
    z-index: 1;
    box-sizing: border-box;
}
input[type="checkbox"] {
    content: "";
    display: block;
    background-color: #ffffff;
}
.disabled-input{
    opacity: 0.4;
}
</style>
